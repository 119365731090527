import React from 'react';
import './Rankings.css';
import { useState, useEffect, useRef, useCallback} from 'react';
import 'firebase/compat/firestore';
import { } from 'firebase/compat/app-check';
import { throttledAdd, throttledDecrease, throttledGetKarma, throttledGetKarmas,throttledSubscribeKarmas} from './KarmaStore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // Import the Realtime Database SDK

const appCheck = firebase.appCheck();
appCheck.activate(
  '6LfuSRklAAAAAGPzZJq0PfMDbwLK3LV-JkbMoiUF',
  true); // true = refresh SDK token as needed

function Rankings({ persons }) {
  const [sortedPersons, setSortedPersons] = useState([]);
  const [karmaSums, setKarmaSums] = useState({ given: 0, taken: 0 });
  const scrollRef = useRef(null);
  const [karma, setKarma] = useState([]);



  //  const handleVote = useCallback(async (id, increment) => {   
  //     setKarma(prevKarma => {
  //     const newKarma = [...prevKarma];
  //     newKarma[id] = (newKarma[id] || 0) + increment;
  //     return newKarma;
  //   });

  //   try {
  //     await increment > 0 ? throttledAdd(id, increment) : throttledDecrease(id, increment);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [setKarma]);

  const handleVote = useCallback(async (id, increment) => {
        /*creates a new array from the previous karma state, updates the karma value at the specified index, and returns the new karma array.*/
    setKarma((prevKarma) => ({
      ...prevKarma,
      [id]: { karma: (prevKarma[id]?.karma || 0) + increment },
    }));
  
    try {
      await increment > 0
        ? throttledAdd(id, increment)
        : throttledDecrease(id, increment);
    } catch (error) {
      console.log(error);
    }
  }, []);
  

  // useEffect(() => {
  //   async function fetchData() {
  //     console.log("awaiting karma");
  //     const karmaData = await throttledGetKarmas();
  //     console.log(karmaData.toString());
  //     setKarma(karmaData);
  //   }
  //   fetchData();
  // }, [karma]);

  useEffect(() => {
    const unsubscribe = throttledSubscribeKarmas(karmaData => {
      setKarma(karmaData);
    });

    return () => {
      unsubscribe();
    };
  }, []);


  /* sorting the person list according to their karma */
  useEffect(() => {
    setSortedPersons(
      persons
        .slice()
        .sort((a, b) => (karma[b.id]?.karma || 0) - (karma[a.id]?.karma || 0))
    );
    setKarmaSums({
      given: Object.values(karma).reduce(
        (sum, val) => (val.karma > 0 ? sum + parseInt(val.karma) : sum),
        0
      ),
      taken: Object.values(karma).reduce(
        (sum, val) => (val.karma < 0 ? sum + parseInt(val.karma) : sum),
        0
      ),
    });
  }, [persons, karma]);
  



  /* helping methods */
  const getColor = (votes) => {
    if (votes === null || votes === undefined || votes === 0) {
      return 'black';
    } else if (votes < 0) {
      return 'darkred';
    } else {
      return 'darkgreen';
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };


  return (
    <div className="Rankings-container">
      <h2>Live Karma Rankings </h2>
      Give or take Karma based on how you feel about People - updated in Realtime <br></br><br></br>
      <div className="karma-given-taken">
        Total Karma Given / Taken : <span style={{ color: 'darkgreen' }}> {karmaSums.given} </span>
      </div>
      <br></br>
      <div className="link btn" onClick={scrollToBottom}>
        Scroll to bottom
      </div>
      {sortedPersons.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <table>
          <tbody>
            {sortedPersons.map((person, index) => (
              <tr key={person.id}>
                <td>
                  <div style={{ textAlign: 'center' }}>
                    <button className="vote-button-up" onClick={() => handleVote(person.id, 1)}>Give</button>
                    <button className="vote-button-down" onClick={() => handleVote(person.id, -1)}>Take</button>
                  </div>
                  <div className="karma-counter" style={{ textAlign: 'center' }}>
                  <span style={{ color: getColor(karma[person.id]?.karma || 0), fontWeight: 'bold' }}>{karma[person.id]?.karma || 0} Karma</span>
                    </div>
                </td>
                <td><h1>{person.name}</h1></td>
                <td style={{ fontWeight: 'bold' }}><h3>{person.occupation}</h3></td>
                <td style={{ fontWeight: 'bold' }}>{person.dob ? Math.round((Date.now() - new Date(person.dob)) / (1000 * 60 * 60 * 24 * 365)) + " years old" : "unknown"}</td>
                <td style={{ fontStyle: 'italic' }}>{person.achievements}</td>
                <td> net worth ~ <div style={{ fontWeight: 'bold' }}>{person.net_worth ? person.net_worth + " $" : "0 $"}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div ref={scrollRef} style={{ height: '100%', overflow: 'auto' }}>
        &nbsp;
      </div>
      <div name="footer">
        <a href="#" onClick={scrollToTop}>Jump to Top</a><br></br><br></br>
        2023 by Karmarankings.com<br></br><br></br>
        Anonymous Vote data is stored <br></br>
        Google Firebase and Analytics<br></br>
        Google Ads<br></br>
      </div>
    </div>
  );
}

export default Rankings;