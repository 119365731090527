import firebase from 'firebase/compat/app';
import { throttle } from 'lodash';
import 'firebase/compat/database'; // Import the Realtime Database SDK

firebase.initializeApp({
    apiKey: "AIzaSyDkgUebyrRIeR7CI_zFTdbUT8dj-nk1IeE",
    authDomain: "karmarankings.firebaseapp.com",
    databaseURL: "https://karmarankings-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "karmarankings",
    storageBucket: "karmarankings.appspot.com",
    messagingSenderId: "267074908408",
    appId: "1:267074908408:web:baab102132472d6124cb0d",
    measurementId: "G-JE53ZXNXKK"
});

const db = firebase.database();

const throttledAdd = throttle(async (id, value) => {
  await db.ref(`karmadb/${id}`).transaction(currentData => {
    if (currentData) {
      currentData.karma += value;
    } else {
      currentData = { karma: value };
    }
    return currentData;
  }, error => {
    if (error) {
      console.log(error);
    }
  });
}, 1000);

const throttledDecrease = throttle(async (id, value) => {
  await db.ref(`karmadb/${id}`).transaction(currentData => {
    if (currentData) {
      currentData.karma += value;
    } else {
      currentData = { karma: value };
    }
    return currentData;
  }, error => {
    if (error) {
      console.log(error);
    }
  });
}, 1000);

const throttledGetKarma = throttle(async (id) => {
  const snapshot = await db.ref(`karmadb/${id}`).once('value');
  const data = snapshot.val();
  return data ? data.karma : 0;
}, 5000);

const throttledGetKarmas = throttle(async () => {
  try {
    const snapshot = await db.ref('karmadb').once('value');
    const data = snapshot.val();
    return data || {};
  } catch (error) {
    console.log(error);
    return {};
  }
}, 5000);

const throttledSubscribeKarmas = throttle((setKarma) => {
  const karmaRef = db.ref('karmadb');
  karmaRef.on('value', (snapshot) => {
    const newKarma = snapshot.val() || {};
    setKarma(newKarma);
  });
  return () => karmaRef.off();
}, 5000);

export { throttledAdd, throttledDecrease, throttledGetKarma, throttledGetKarmas, throttledSubscribeKarmas };