import './App.css';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import Rankings from './components/Rankings';
import AsciiBanner from './components/AsciiBanner';

function App() {

  const [persons, setPersons] = useState([]);

  useEffect(() => {

    fetch('persons.json')
      .then(response => response.json())
      .then(data => {
        setPersons(data.persons);
      }).catch(error => console.log(error));

  }, []);

  return (

    <>
      <Helmet>
        <title>Global Karma Rankings</title>
        <meta name="description" content="Karma Rankings allows people to take or give Karma to whoever deserves it" />
        <link rel="canonical" href="https://www.karmarankings.com" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="App">
        <header className="App-header">
          <div id="amzn-assoc-ad-5780916f-3f83-44af-86ee-c03da2240e13"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=5780916f-3f83-44af-86ee-c03da2240e13"></script>
          <div className="logo-container">
            <div className="logo-image">
              <img src="karma_rank_logo.png" alt="karmarankings.com logo" className="responsive-logo" />
            </div>

          </div>
          <Rankings persons={persons} />
        </header>
        {/* <AsciiBanner/> */}
      </div>
    </>
  );
}

export default App;
